<script setup lang="ts">
import type { SubscriptionType } from '@respell/database';
import { subscriptionDetails } from '@respell/utils';

const workspaceStore = useWorkspaceStore();
const {
  workspaceId,
  subscriptionType,
  freeActions,
  monthlyActions,
  monthlyActionLimit,
  monthlyAddonTasks,
} = storeToRefs(workspaceStore);

const subscriptionName = computed(
  () =>
    subscriptionDetails[subscriptionType.value as SubscriptionType].shortName,
);

const iconName = computed(
  () => subscriptionDetails[subscriptionType.value as SubscriptionType].icon,
);

const atLimit = computed(
  () =>
    monthlyActions.value >=
    monthlyAddonTasks.value + freeActions.value + monthlyActionLimit.value,
);

const { pending } = useAsyncCache(
  `spellMeter/${workspaceId}`,
  () => workspaceStore.fetchSpellMeter(),
  {
    lazy: true,
  },
);

const showMeter = computed(() => monthlyActionLimit.value !== null);
</script>
<template>
  <USkeleton v-if="pending" class="h-16 w-full" />
  <div
    v-else-if="showMeter"
    class="hover:bg-gray-50 p-4 rounded hover:cursor-pointer"
    @click="navigateTo('/account/billing')"
  >
    <div class="flex justify-between">
      <div class="flex gap-x-1 font-semibold mb-2 truncate">
        <UIcon :name="iconName" class="text-primary w-5 h-5" />
        {{ subscriptionName }}
      </div>
      <p class="dimmed mb-2">
        {{ monthlyActions }} /
        {{ monthlyActionLimit + freeActions + monthlyAddonTasks }}
      </p>
    </div>
    <UMeter
      :value="monthlyActions"
      :max="monthlyActionLimit + freeActions + monthlyAddonTasks"
      :color="atLimit ? 'red' : 'primary'"
    />
    <p v-if="atLimit" class="text-red-500 text-xs">
      You have reached your action limit.
    </p>
  </div>
</template>
