<template>
  <div
    class="grow flex h-full justify-start bg-white max-w-full overflow-hidden items-start max-h-screen"
  >
    <HomeSidebar v-if="!guest" />
    <div
      v-if="containerless"
      class="relative grow max-w-full overflow-y-scroll max-h-full"
    >
      <slot />
    </div>
    <!-- Remove container padding if full is true -->
    <UContainer v-else class="overflow-y-scroll max-h-full max-w-full">
      <slot />
    </UContainer>
  </div>
</template>
<script setup lang="ts">
const { containerless, guest } = useAttrs();
</script>
